<template>
  <div>
    <SpinnerComponent v-if="isLoading" />
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt-6"
         :class="{ 'blur-filter': isLoading }"
    >
      <div class="alert" :class="[`alert-${apiStatus}`]" v-if="apiStatus" >
        {{ apiMessage }}
      </div>
      <div class="row">
        <div class="col-md-6">
          <Card>
            <div slot="header">
              <h3 class="mb-0">Promocja produktowa</h3>
            </div>
            <div class="row justify-content-between">
              <div class="col-md-3">
                <base-input label="Czy aktywna?">
                  <base-switch v-model="active" />
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input label="Nazwa promocji" v-model="name" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input
                  label="Wysokość promocji"
                  v-model="discountPercent"
                  @change="e => handlePercent({
                    field: 'discount',
                    value: e.target.value
                  })"
                />
              </div>
              <div class="col-md-6">
                <base-input label="Dodanie tagu">
                  <el-select v-model="tag"
                             filterable
                             placeholder="Wybierz tag">
                    <el-option v-for="option in tagList"
                               :key="option.value"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input label="Data od">
                  <datepicker v-model="dateFrom" />
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input label="Data do">
                  <datepicker v-model="dateTo" />
                </base-input>
              </div>
            </div>
          </Card>
        </div>
        <div class="col-md-6">
          <Card>
            <div>
              <base-input label="Promocja dla wybranych produktów" placeholder="">
                <el-select v-model="products"
                           multiple
                           filterable
                           placeholder="Wybierz produkty, których dotyczy promocja"
                >
                  <el-option v-for="option in productList"
                             :key="option.value"
                             :label="option.label"
                             :value="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </Card>
        </div>
      </div>
      <div class="text-right">
        <div class="btn btn-primary" @click="handleSubmit">Zapisz promocję</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import Card from "@/components/Cards/Card";
import Datepicker from "@/components/Datepicker";
import { Select, Option } from 'element-ui'
import SpinnerComponent from "@/components/Atoms/Spinner";

export default {
  name: 'ProductPromotionAdd',
  components: {
    SpinnerComponent,
    BreadcrumbHeader,
    Card,
    Datepicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      isLoading: false,
      discountPercent: '',
    }
  },
  computed: {
    productList() {
      return this.$store.state.ProductPromotionManageModule.productList
    },
    tagList() {
      return this.$store.state.ProductPromotionManageModule.tagList
    },
    name: {
      get() {
        return this.$store.state.ProductPromotionManageModule.form.name
      },
      set(newVal) {
        this.$store.commit('ProductPromotionManageModule/updateValues', {
          field: 'name',
          values: newVal
        })
      }
    },
    active: {
      get() {
        return this.$store.state.ProductPromotionManageModule.form.active
      },
      set(newVal) {
        this.$store.commit('ProductPromotionManageModule/updateValues', {
          field: 'active',
          values: newVal
        })
      }
    },
    dateFrom: {
      get() {
        const date = this.$store.state.ProductPromotionManageModule.form.dateFrom
        if ( date ) {
          return new Date(date)
        }
        return new Date()
      },
      set(newVal) {
        this.$store.commit('ProductPromotionManageModule/updateValues', {
          field: 'dateFrom',
          values: newVal
        })
      }
    },
    dateTo: {
      get() {
        const date = this.$store.state.ProductPromotionManageModule.form.dateTo
        if ( date ) {
          return new Date(date)
        }
        return new Date()
      },
      set(newVal) {
        window.date = newVal
        this.$store.commit('ProductPromotionManageModule/updateValues', {
          field: 'dateTo',
          values: newVal
        })
      }
    },
    apiStatus() {
      return this.$store.state.ProductPromotionManageModule.apiStatus
    },
    apiMessage() {
      return this.$store.state.ProductPromotionManageModule.apiMessage
    },
    tag: {
      get() {
        return this.$store.state.ProductPromotionManageModule.form.tag
      },
      set(newVal) {
        this.$store.commit('ProductPromotionManageModule/updateValues', {
          field: 'tag',
          values: newVal
        })
      }
    },
    products: {
      get() {
        return this.$store.state.ProductPromotionManageModule.form.products
      },
      set(newVal) {
        this.$store.commit('ProductPromotionManageModule/updateValues', {
          field: 'products',
          values: newVal
        })
      }
    },
    breadcrumb() {
      return [
        {title: 'Promocje'},
        {title: 'Promocje produktowe', link: {name: 'product-promotion-list'}},
        {title: 'Nowa promocja'}
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('ProductPromotionManageModule/fetchTags');
    await this.$store.dispatch('ProductPromotionManageModule/fetchProducts');

    if ( this.$route.query.id || this.$route.params.id ) {
      const id = this.$route.query.id || this.$route.params.id
      await this.$store.dispatch('ProductPromotionManageModule/fetchProductPromotionInfo', id )
    }

    ['discount'].forEach( field => {
      this[`${field}Percent`] = this.parsePercent(this.$store.state.ProductPromotionManageModule.form[field])
    })
    this.isLoading = false;
  },
  methods: {
    ...mapActions('ProductPromotionManageModule', ['saveProductPromotionInfo', 'deleteProductPromotion', 'fetchProducts']),
    parsePercent(percent) {
      return ( percent / 100 ).toFixed(2).replace('.', ',') + ' %'
    },
    async handleSubmit() {
      await this.saveProductPromotionInfo(this.$route.query.id || this.$route.params.id)
    },
    async handlePercent({ field, value }) {
      this.$store.commit('ProductPromotionManageModule/updateValues', {
        field,
        values: (value.split(' %').join('').replace(',', '.') * 100)
      })

      this[`${field}Percent`] = this.parsePercent(this.$store.state.ProductPromotionManageModule.form[field])
    },
    async handleDelete() {
      await this.deleteProductPromotion('bfa5d7f8-aaf1-402c-a4ae-50259a00a608');
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('ProductPromotionManageModule/resetForm')
    next();
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style lang="scss">
.blur-filter {
  filter: blur(2px);
}
</style>
